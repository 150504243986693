<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Benzoic acid has limited solubility in water, but its conjugate base is completely soluble
        in water.
      </p>
      <p class="mb-2">
        Choose the product formed when benzoic acid is treated with aqueous sodium hydroxide:
      </p>
      <p class="mb-4">
        <v-img style="max-width: 500px" src="/img/assignments/ChemUCI51LBA1Q14Header.png" />
      </p>

      <v-radio-group v-model="inputs.input1" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsShuffled"
          :key="option.value"
          class="my-2"
          :value="option.value"
        >
          <template #label>
            <v-img :src="option.img" style="max-width: 180px" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUCI51LBA1Q14',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options: [
        {img: '/img/assignments/ChemUCI51LBA1Q14_optionA.png', value: 'a'},
        {img: '/img/assignments/ChemUCI51LBA1Q14_optionB.png', value: 'b'},
        {img: '/img/assignments/ChemUCI51LBA1Q14_optionC.png', value: 'c'},
        {img: '/img/assignments/ChemUCI51LBA1Q14_optionD.png', value: 'd'},
        {img: '/img/assignments/ChemUCI51LBA1Q14_optionE.png', value: 'e'},
        {img: '/img/assignments/ChemUCI51LBA1Q14_optionF.png', value: 'f'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
